<template>
  <div class="fill-view">

    <div class="v-center">
      <div class="container">
        <div class="card">
          <div class="card-block">
            <h3>Page Not Found</h3>
            <p><a href="/">&larr; Return to Entrance</a></p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'NotFoundPage',
  components: {
  },
  data: function(){
    return {
    }
  },
  methods:{
  },
  mounted: function(){
    console.log('NotFoundPage.mounted');
  }

}
</script>

<style scoped>
.fill-view {
  background-color: #def;
  background-size: auto 100vh;
  background-position: top center;
  min-height: 100vh;
  color: #007;
}
/* Responsive Background Image */
@media (min-aspect-ratio: 1/1) {
  .fill-view {
    background-size: 100%;
  }
}

.v-center {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50%;
  transform: translateY(50%);
}

.container {
  max-width: 40rem;
}

.card {
  box-shadow: 0 3px 6px rgba(0,0,0,0.375);
  border-radius: 0;
}
.card-block {
  margin: 0.75rem;
  padding: 1.25rem 0.5rem 0.25rem;
  border: 1px solid #007;
}

</style>
